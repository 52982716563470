import React, { useState, useEffect } from 'react';
import "./home.scss";
import Button from '../components/UI/Button'
import { useHistory } from 'react-router-dom'
import AuthHeader from '../components/Layout/Auth/Header'

const Home = () => {
  let history = useHistory();
  const goLogin = ()=>{
    history.push('/auth/login')
  }
  return (
    <div id='home'>
      <AuthHeader className={'header-home'}/>
      <div className={'home-start d-flex flex-column justify-content-center align-items-center'}>
        <h3 className={'fw-bold text-center'}>Kelase Mobile Training Platform</h3>
        <p className={'text-center'}>&quot;Belajar dan Meningkatkan Keterampilan Kapan Saja dan Dimana Saja&quot;</p>
        <Button primary={true} className={'button-start'} onClick={goLogin}>
          Mulai
        </Button>
      </div>
      <p className="text-center">© Hak Cipta 2025 – Dilindungi Undang-Undang. Kelase (Kelase.Com) dikembangkan oleh <br />
          <a href="https://edukasi101.com/" className='fw-bolder' target="_blank">PT. Edukasi Satu
            Nol Satu, Indonesia</a>
        </p>
    </div>
  )
}

export default Home;
