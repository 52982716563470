import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import AuthHeader from '../../components/Layout/Auth/Header'
import {FaEnvelope, FaKey, FaExclamationCircle} from 'react-icons/fa'
import './auth.scss';
import Input from '../../components/UI/Input'
import Button from '../../components/UI/Button'
import { connect } from 'react-redux'
import { login, loginSocial } from '../../redux/actions/userAction'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import SocialButton from '../../components/Layout/Auth/SocialButton'


interface LoginProps extends RouteComponentProps{
 user: InitialStateUserType;
 UI: InitialStateUIType;
 login: Function;
 loginSocial: Function;
}

const Login: React.FC<LoginProps> = (props:LoginProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const goBack = () =>{
    props.history.goBack();
  }
  const goToSignup = () =>{
    props.history.push('/auth/sign-up');
  }
  const onSubmit = async (data: any) => {
    await props.login(data);
  }

  const ShowError = () =>{
    return (
      <div className='alert alert-danger mb-3 d-flex align-items-center'>
        <FaExclamationCircle />
        <p className='m-0 mx-2'>{props.UI.message}</p>
      </div>
    )
  }

  useEffect(()=>{
    if(props.user.authenticated){
      props.history.push('/competency')
    }
  },[props.user])

  return (
    <div className={'main-content'}>
      <AuthHeader back={goBack} />
      <h1 className={'mb-5 fw-bolder'}>Login to Your Account</h1>
      <div className={'form-wrapper'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {props.UI.error && <ShowError />}
          <div className="form-group mb-3">
            <Input
              placeholder='Email'
              name='email'
              register={register}
              errors={errors}
              block={true}
              rules={{
                required: "Email is required",
              }}
              Icon={FaEnvelope}
            />
          </div>
          <div className="form-group mb-3">
            <Input
              placeholder='Password'
              name='password'
              type='password'
              register={register}
              errors={errors}
              block={true}
              rules={{
                required: "Password is required",
              }}
              Icon={FaKey}
            />
          </div>

          <div className="form-group mb-3">
            <Button primary block={true}>
              Log In
            </Button>
          </div>
        </form>
      </div>
      <a className={'cursor-pointer text-primary mb-5'} onClick={() => {
        props.history.push('/auth/forgot-password')
      }}>Forgot Password?</a>
      <div className="or-wrapper mb-4">
        <div className='or-container'>
          <div className='or-content'>or continue with</div>
        </div>
      </div>
      <SocialButton  {...props} className='mb-4' />
      <div className='d-flex w-100 justify-content-center mb-5'>
        <span className='text-muted'>Don't Have an Account?</span>
        <a className='text-primary mx-2' onClick={goToSignup.bind(this)} rel='noreferrer'>Sign Up</a>
      </div>
      <p className="text-center">© Hak Cipta 2025 – Dilindungi Undang-Undang. Kelase (Kelase.Com) dikembangkan oleh <br />
        <a href="https://edukasi101.com/" className='fw-bolder' target="_blank" rel='noreferrer'>PT. Edukasi Satu
          Nol Satu, Indonesia</a>
      </p>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
const mapDispatchToProps = { login, loginSocial };
export default connect(mapStateToProps,mapDispatchToProps)(Login);
