import {FaFacebook,FaGoogle} from 'react-icons/fa'
import {firebaseConfig} from '../../../utils/constant';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { LOADING_UI, SET_ERRORS, UNLOADING_UI } from '../../../redux/types'
import './social-button.scss';
import { UseAppDispatch } from '../../../redux/store'

interface ISocialButton{
  className?:string;
  loginSocial: Function;
}
const SocialButton = (props: ISocialButton) => {
  const dispatch = UseAppDispatch();
  let classes = 'social-button';
  if(props.className){
    classes+=' '+props.className;
  }
  const app = initializeApp(firebaseConfig);
  const googleSignIn = () => {
    dispatch({
      type:LOADING_UI
    });
    const provider = new GoogleAuthProvider();
    provider.addScope('email,profile');
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user: any = result.user;
        await props.loginSocial({
          login_source: 'google',
          token: user?.accessToken ?? null,
          email: user.providerData[0].email,
          fullname: user.providerData[0].displayName,
          google_id: user.providerData[0].uid
        })
      }).catch((error) => {
        dispatch({
          type: SET_ERRORS,
          payload: {
            error:true,
            message: 'Something Wrong. Please Try Again'
          }
        });
      }).finally(()=>{
        dispatch({
          type:UNLOADING_UI
        });
      });
  }

  const facebookSignIn = () =>{
    dispatch({
      type:LOADING_UI
    });
    const provider = new FacebookAuthProvider();
    provider.addScope('email,public_profile');
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user:any = result.user;
        if (user.providerData[0].email) {
          await props.loginSocial({
            login_source: 'facebook',
            token: user?.accessToken ?? null,
            email: user.providerData[0].email,
            fullname: user.providerData[0].displayName,
            facebook: user.providerData[0].uid
          })
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: {
              error:true,
              message: 'Something Wrong. Please Try Again'
            }
          });
        }
      }).catch((error) => {
        dispatch({
          type: SET_ERRORS,
          payload: {
            error:true,
            message: 'Something Wrong. Please Try Again'
          }
        });
      }).finally(()=>{
        dispatch({
          type:LOADING_UI
        });
      });
  }
  return (
    <div className={classes}>
      <a onClick={googleSignIn.bind(this)}>
        <FaGoogle size={'2rem'}/>
      </a>
    </div>
  )
}
export default SocialButton;
