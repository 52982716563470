import { UserType } from '../typings/user'

export const isAuthenticated = (): boolean => {
  const _kmt_t = localStorage.getItem("_kmt_t");
  return !!_kmt_t;
}

export const getToken = (): string | null => {
  return localStorage.getItem("_kmt_t");
}

export const getUser = (): UserType | undefined => {
  const _kmt_t = localStorage.getItem("_kmt_u");
  let user;
  if(_kmt_t){
    user = JSON.parse(_kmt_t) as UserType;
  }
  return user;
}

const isValidEmail = (email:any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
const isStrongPassword = (password:any) =>
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#\-_?&])[A-Za-z\d@$!%*#\-_?&]{8,50}$/.test(
    password
  );

export const EmailValidation = (email:any) =>{
  return isValidEmail(email) || "email is not in valid format";
};
export const PasswordValidation = (password:any) =>{
  return isStrongPassword(password) || "minimum length 8, must contain at least one uppercase, one lowercase and special character";
};

export const formatNumber = (val: number) => {
  return val.toLocaleString('ID-id')
}

export const BEUrl = () => process.env.REACT_APP_API_URL?.replace('api','') || ''

export const imagePath = (type: string = 'course', image: string) => {
  return `${BEUrl()}static/${type}/${image}`
}

export function copyValue(val: string){
  const el = document.createElement('input');
  el.value = val;
  document.body.appendChild(el);
  try {
    if(navigator.userAgent.match(/ipad|ipod|iphone/i)){
      const range = document.createRange();
      el.contentEditable = "true";
      el.readOnly = false;
      range.selectNodeContents(el);

      const s = window.getSelection();
      // @ts-ignore
      s.removeAllRanges();
      // @ts-ignore
      s.addRange(range);
      el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
      document.execCommand('copy');
    }else{
      el.select();
      document.execCommand('copy');
    }
  }
  catch (err) {
    console.log(err,'please press Ctrl/Cmd+C to copy');
  }
  document.body.removeChild(el);
}

export function humanFileSize(bytes: number, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export const getExtFile = (filename: string): string => {
  const fileSplit = filename.split('.');
  return fileSplit[fileSplit.length - 1] as string
}

export function isYoutubeUrl(url: string) {
  const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return !!url.match(p);

}

export function getYoutubeID(url: any): string {
  let ID = ''
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i)
    ID = ID[0]
  } else {
    ID = url
  }
  return ID
}
