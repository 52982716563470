import React, {useEffect, useState} from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { InitialStateUIType, InitialStateUserType } from '../../typings/state'
import {connect, useDispatch} from 'react-redux'
import { logout } from '../../redux/actions/userAction'
import './account.scss';
import {BiBook, BiUser, BiLogOut, BiCart, BiPlus} from 'react-icons/bi'
import { FaChevronRight } from 'react-icons/fa'
import {useQuery} from "react-query";
import {authenticatedRequestRaw} from "../../services/api.service";
import {LOADING_UI, UNLOADING_UI} from "../../redux/types";
import {UserType} from "../../typings/user";
import {BsPersonBoundingBox} from "react-icons/bs";
import { Modal } from 'react-bootstrap'
import Button from '../../components/UI/Button'
import { useForm } from 'react-hook-form'
import Input from '../../components/UI/Input'
import {toast} from 'react-toastify'
import axios from "axios";
import {getToken} from "../../utils/common";

const menu = [
  {
    key: 'profile',
    label: 'Sunting Profil',
    path:'/account/profile',
    icon: BiUser
  },
  {
    key: 'course',
    label: 'Kursus Saya',
    path:'/account/my-course',
    icon: BiBook
  },
  {
    key: 'competency',
    label: 'Tes Kompetensi Saya',
    path:'/account/my-competencies',
    icon: BsPersonBoundingBox
  },
  {
    key: 'transaction',
    label: 'Transaksi',
    path:'/transaction',
    icon: BiCart
  },
]
interface AccountProps extends RouteComponentProps{
  user: InitialStateUserType;
  UI: InitialStateUIType;
  logout: Function;
}
const Account: React.FC<AccountProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: profile, isLoading, refetch} = useQuery<UserType, boolean>('profileDetail', async () => {
    const response = await authenticatedRequestRaw('GET','/profile/detail')
    return response?.data?.data
  })
  const [modalGroup, setModalGroup] = useState(false)
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmitGroup = async (data: any) => {
      setModalGroup(false)
      reset();
      dispatch({ type: LOADING_UI })
      axios({
        url: process.env.REACT_APP_API_URL + '/profile/apply-group',
        method: 'post',
        data: {group_code: data.group_code},
        headers: {
          "Authorization": `Bearer ${getToken()}`,
        },
      }).then(response => response.data).catch((err) => {
        throw err.response;
      }).finally(() => {
        dispatch({ type: UNLOADING_UI })
        toast.success("Tambah Grup Berhasil!", {
          position: toast.POSITION.TOP_CENTER
        });
        refetch();
      });
  }

  useEffect(() => {
    dispatch({ type: isLoading? LOADING_UI : UNLOADING_UI })
  },[isLoading, dispatch])
  const onLogout = async () => {
    await props?.logout();
  }
  const goTo = (path: string) => {
    history.push(path)
  }

  const BadgePendingTrans = () => {
    if(profile && profile?.pending_transaction){
      if( profile?.pending_transaction > 0){
        return (
          <span className="badge rounded-pill text-bg-danger me-2">{profile?.pending_transaction}</span>
        )
      }
    }
    return null
  }
  const BadgeMyCourse = () => {
    if(profile){
      return (
        <span className="badge rounded-pill text-bg-primary me-2">{profile?.my_courses}</span>
      )
    }
    return null
  }

  const BadgeMyCompetencies = () => {
    if(profile){
      return (
        <span className="badge rounded-pill text-bg-primary me-2">{profile?.my_competencies}</span>
      )
    }
    return null
  }

  return (
    <>
      <div className='profile-header d-flex flex-column align-items-center pb-4 mb-4 border-bottom w-100'>
        <img src={profile?.avatar ?? 'https://placehold.co/120x120' }
             className='profile-header-image mb-3 rounded-circle' alt='logo'/>
        <h5 className='fw-bold mb-2'>{profile?.fullname}</h5>
        <span className=''>{profile?.email}</span>
        <div className='d-flex gap-2 align-items-center mt-2'>
          { 
            profile?.groups && profile?.groups?.length > 0 ? <>
              {
                profile?.groups?.map((g, index) => (<span className="badge text-bg-primary" key={`group-${index}`}>{g.group.name}</span>))
              }
            </> : null
          }
          <span className="badge text-bg-secondary cursor-pointer" onClick={() => setModalGroup(true)}><BiPlus /> Grup</span>
        </div>
        
      </div>
      <ul className='list-group w-100 list-menu'>
        {menu.map((m,index) => {
          return (
            <li
              className='list-group-item d-flex justify-content-between list-menu-item align-items-center cursor-pointer'
              key={`menu${index}`}
              onClick={() => goTo(m.path)}
            >
              <div className='d-flex align-items-center'>
                <m.icon size={'1.5rem'}/>
                <span className='mx-3'>{m.label}</span>
              </div>
              <div className='d-flex align-items-center'>
                {
                  m.key === 'transaction'? <BadgePendingTrans /> : null
                }
                {
                  m.key === 'course'? <BadgeMyCourse /> : null
                }
                {
                  m.key === 'competency'? <BadgeMyCompetencies /> : null
                }
                <FaChevronRight />
              </div>
            </li>
          );
        })}
        <li
          className='list-group-item d-flex justify-content-between list-menu-item align-items-center cursor-pointer'
          onClick={onLogout.bind(this)}
        >
          <div className='d-flex align-items-center'>
            <BiLogOut size={'1.5rem'}/>
            <span className='mx-3'>Keluar</span>
          </div>
          <FaChevronRight />
        </li>
      </ul>
      <Modal show={modalGroup} onHide={()=>{ setModalGroup(false) }} centered={true}>
        <Modal.Body>
          <div className={'form-wrapper'}>
            <form onSubmit={handleSubmit(onSubmitGroup)}>
              <div className="form-group mb-3">
                <label className={'form-label'}>Kode Grup</label>
                <Input
                  placeholder='Masukan Kode Grup'
                  name='group_code'
                  register={register}
                  errors={errors}
                  block={true}
                  rules={{
                    required: "Kode Grup tidak boleh kosong",
                  }}
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button secondary onClick={()=>{ setModalGroup(false) }}>
            Batal
          </Button>
          <Button primary className={'min-w-75px'} onClick={handleSubmit(onSubmitGroup)}>
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    UI: state.UI,
  };
};
const mapDispatchToProps = { logout };
export default connect(mapStateToProps,mapDispatchToProps)(Account);
